@import '_variables';

#wtd2023InteractiveMap.App {
  @include body-copy;
  position: relative;
  text-align: left;
  height: 100%;
  min-height: 930px;
}

.nav.nav-tabs {
  display: flex;
  position: absolute;
  top: 158px;
  right: 40px;
  z-index: 1;
  border: none;
  background-color: #f2f2f2; /* Light gray background */
  border: $nsw-blue 3px solid ;
  box-sizing: border-box !important;
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* To make sure the inner contents are contained */
  padding-left: unset !important;
  list-style: none !important;
  // @include button;
  // box-sizing: border-box!important;
  .nav-item {
    width: 50% !important; /* Divide the width equally between tabs */
    text-align: center !important; /* Center-align text */
    font-size: 16px !important;
    padding-left: unset !important;
    margin-top: 0 !important;
    line-height: 24px !important;
  }
  .nav-item::before {
    display: none !important;
  }
  .nav-link {
    color: $nsw-blue;
    border: none; /* Remove border */
    position: relative; /* Position relative to parent */
    padding: 8px 16px;
  }
  .nav-link:hover {
    color: $nsw-blue;
    border-color: $secondary-blue;
    cursor: pointer;
  }
  .nav-link.active {
    color: $nsw-blue;
    background-color: white; /* Bootstrap primary color for active tab */
    color: $nsw-blue; /* White text for active tab */
    font-weight: 700;
  }

  .nav-link::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white; /* Bootstrap primary color */
    opacity: 0; /* Start as invisible */
    z-index: -1; /* Behind the text */
    transition: opacity 0.3s ease-in-out; /* Transition effect */
  }
  
  .nav-link.active::before {
    opacity: 1; /* Make it fully visible for active tab */
  }
}

.tab-pane.fade {
  opacity: 1 !important;
}


/* Smartphone styles */
@media only screen and (max-width: 767px) {
  .nav.nav-tabs {
    top:122px;
    right: 25px;
    height: 50px;
    .nav-link {
      padding: 0 16px;
      height: 100%;
      box-sizing:border-box;
    }
  }
  /* Add your styles for smartphones here */
  #wtd2023InteractiveMap.App {
    min-height: 1050px;
  }
}