@import "../../variables";

@keyframes rotateContainer {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(
      -360deg
    ); /* Negative to counteract the container's rotation */
  }
}

@keyframes bounceEffect {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-7px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-button,
.mapboxgl-ctrl-attrib.mapboxgl-compact-show .mapboxgl-ctrl-attrib-inner {
  box-sizing: border-box;
}

.Map {
  border: 5px solid $primary-orange;
  position: absolute;
  top: 260px;
  left: 20px;
  width: calc(100% - 30px);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.1);
}

.mapContainer {
  width: 100%;
  min-height: 410px;
  background-color: $secondary-orange;
  // Override important rules on parent site
  *,
  ::after,
  ::before {
    box-sizing: border-box !important;
  }

  input {
    @include body-copy;
    height: 42px;
    padding: 6px 32px;
    font-size: 15px;
  }

  svg {
    top: 10px;
    left: 8px;
  }

  ul {
    list-style-type: none !important;
    li {
      padding: 0 !important;
      margin: 0 !important;
      ::before {
        height: 0;
      }
      a {
        font-size: 15px !important;
        line-height: 24px !important;
        color: rgb(2, 40, 99) !important;
        font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
        text-decoration: none !important;
        letter-spacing: normal !important;
      }
    }
    > div:last-child {
      font-size: 10px;
      padding-left: 12px;
    }
  }
  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      top: 6px;
      left: 6px;
    }
  }
}

.infoContainer {
  min-height: 180px;
  max-height: 300px;
  overflow-y: auto;
  border: solid $primary-orange;
  border-width: 5px 0 0 0;
  background-color: $secondary-orange;
  display: flex;
  flex-direction: row;
  padding: 30px;
  box-sizing: border-box;

  #gemIcon {
    width: 110px !important;
    height: 110px !important;
  }

  .icon {
    position: relative;
    width: 110px !important;
    height: 110px !important;

    &.single {
      animation: bounceEffect 1s ease;
      // transform: translateY(-100vh); /* Start off-screen */
      transition: transform 0.5s ease;
    }

    &.group {
      // margin: 150px; /* To ensure enough space for the rotation and view */
      animation: rotateContainer 10s infinite linear;
      position: relative;
      // opacity: 0; /* Start as invisible */
      // animation: fadeIn 0.8s forwards;
      /* Random delay between 0 and 2 seconds for each word */
      animation-delay: 0.1s;
    }

    #gemRuby,
    #gemEmerald,
    #gemDiamond {
      position: absolute;
      width: 50px !important;
      height: 50px !important;
      animation: rotateIcon 10s infinite linear;
    }

    #gemRuby {
      top: 10%;
      left: 45%;
      transform: translateX(-50%); /* Centered on the container */
    }

    #gemEmerald {
      top: 10%;
      left: 0%;
    }

    #gemDiamond {
      bottom: 5%;
      left: 10%;
      // transform: translateX(-50%);
    }
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    .message {
      max-width: 880px;
      color: $nsw-blue;
      font-family: Public Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 20px 0 !important;
      .word {
        display: inline-block; /* Allows the fade-in animation for each word */
        opacity: 0; /* Start as invisible */
        animation: fadeIn 0.8s forwards;
        /* Random delay between 0 and 2 seconds for each word */
        animation-delay: calc(var(--delay) * 0.1s);
      }
      em {
        font-size: 18px;
      }
    }
    .sender {
      color: $nsw-blue;
      font-size: 20px;
      font-weight: 200;
      opacity: 0; /* Start as invisible */
      animation: fadeIn 0.8s forwards;
      /* Random delay between 0 and 2 seconds for each word */
      animation-delay: 1s;
    }
    &.intro {
      .message {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2em;
        margin-bottom: 15px;
      }
      .sender {
        font-size: 14px;
        font-weight: 200;
      }
    }
  }
}

.infoContainer > * + * {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .Map {
    top: 220px;
    left: 10px;
    width: calc(100% - 20px);
    .mapContainer {
      width: 100%;
      min-height: 330px;
      max-height: 330px;
    }
    .infoContainer {
      min-height: 280px;
      max-height: 400px;
      flex-direction: column;
      .icon {
        // top: -10px;
        &.single {
          margin: 0 auto;
        }
        &.group {
          margin: 0px auto;
        }
      }
    }
    .infoContainer > * + * {
      margin-left: 0;
    }
    .messageContainer {
      margin-top: 15px;
      overflow: scroll;
      .message {
        font-size: 22px;
      }
      &.intro {
        .message {
          font-size: 16px;
          line-height: 1.2em;
          font-weight: 500;
        }
        .sender {
          font-size: 14px;
        }
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Slight white to enhance the frosted effect */
  display: flex;
  align-items: center; /* Center spinner vertically */
  justify-content: center; /* Center spinner horizontally */

  /* The frosted glass effect */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); /* For Safari compatibility */
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 2;
}

.spinner {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  transform: translate(calc(50% - 60px), calc(50% - 60px));
  border: 16px solid white;
  border-radius: 50%;
  border-top: 16px solid $primary-orange;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
