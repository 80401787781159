@import '../../_variables';

.Filter {
  @include body-copy;
  width: 261px;
  > div:first-of-type {
    border: 3px solid $primary-orange;
  }
  input {
    height: 28px !important;
  }
}