@import "../../variables";

@keyframes riseAnimation {
  0% {
    transform: translateY(100%); /* Starts from the bottom */
  }
  100% {
    transform: translateY(5px); /* Ends at its original position */
  }
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px); /* Adjust for more or less float */
  }
}

.Header {
  width: calc(100% - 20px);
  padding: 20px;
  box-sizing: border-box !important;
  border: $primary-blue 5px solid;
  min-height: 870px;
  background: $secondary-blue;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .heading {
    max-width: 500px;
    font-size: 32px;
    line-height: 1.2em;
    @include heading-sans-serif;
    text-align: left;
    margin: 0 0 10px 0;
  }

  .callToAction {
    text-align: left;

    .instruction {
      margin: 10px 0;
      @include body-copy;
      &.disabled {
        border-color: #444;
        color: #444;
      }
    }

    .button {
      @include button;
      box-sizing: border-box !important;
      &.disabled {
        border-color: #999;
        color: #999;
      }
    }
  }

  .counter {
    // font-size: 13px;
  }

  .motif {
    width: 370px !important;
    height: 150px !important;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    top: 0;

    will-change: transform;
    transform: translateY(100%);

    animation: riseAnimation 2s forwards, floatAnimation 3s infinite 2s;
  }
}

@media only screen and (max-width: 767px) {
  // Embed

  .Header {
    width: calc(100% - 10px);
    padding: 10px;
    max-height: 800px;
    min-height: 800px;
    margin-top: 0;

    .heading {
      font-size: 22px;
    }

    .callToAction {
      .instruction {
        font-size: 16px;
      }
      .button {
        width: 150px;
        height: 50px;
        box-sizing: border-box !important;
        // display: none;
      }
    }

    .counter {
      font-size: 14px;
    }

    #twibbonMotif,
    .motif {
      width: 120px !important;
      height: 120px !important;
      right: 0;
      top: 0px;
    }
  }
}
