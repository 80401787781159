@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap');

$primary-orange: #F9AE2B;
$secondary-orange: #FFF3D1;
$primary-blue: #2071F9;
$secondary-blue: #8FE1FE;
$primary-green: #18A94A;
$secondary-green: #AAECB5;
$tertiary-green: #DCFAE0;
$nsw-blue: #022863;

@mixin body-copy {
  font-family: 'Public Sans', sans-serif;
  font-weight: 300;
  color: $nsw-blue;
}

@mixin heading-sans-serif {
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  color: $nsw-blue;
}

@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  font-size: 18px;
  width: 250px;
  height: 50px;
  @include heading-sans-serif;
  background-color: white;
  color: $nsw-blue;
  border: 3px solid $nsw-blue;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  user-select: none;

  &:hover {
    background-color: #fefefe;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
  }

  &:active {
    background-color: #fefefe;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(1px);
  }
}