@import "../../variables";

.tableContainer {
  // box-sizing: border-box;
  border: 5px solid $primary-orange;
  position: absolute;
  top: 220px;
  left: 20px;
  width: calc(100% - 50px);
  min-height: 600px;
  max-height: 600px;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  padding: 10px;
  .Table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      padding: 10px; /* You can adjust the value as per your requirement */
      border: 1px solid #ddd;
      text-align: left;
    }
    thead {
      border-bottom: 1px solid $primary-blue;
    }
    /* Alternate row background colors */
    tr:nth-child(even) {
      background-color: #f2f2f2; /* Light gray for even rows */
    }

    tr:nth-child(odd) {
      background-color: #ffffff; /* White for odd rows */
    }
    td {
      font-size: 14px;
      span {
        display: block;
        margin: 5px 0;
      }
      .date,
      .postcode {
        display: none;
      }
    }
    caption {
      caption-side: bottom;
    }
    @media screen and (max-width: 480px) {
      box-sizing: border-box;
      table-layout: fixed;
      th {
        font-size: 14px;
      }
      td {
        font-size: 14px;
        .date,
        .postcode {
          display: block;
          font-size: 0.9em;
        }
      }
    }
  }
}

.tableContainer::-webkit-scrollbar {
  width: 0.5em;
  background-color: $secondary-orange;
}

/* Handle */
.tableContainer::-webkit-scrollbar-thumb {
  background: $primary-orange;
}

/* Handle on hover */
.tableContainer::-webkit-scrollbar-thumb:hover {
  background: $primary-orange;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Slight white to enhance the frosted effect */
  display: flex;
  align-items: center; /* Center spinner vertically */
  justify-content: center; /* Center spinner horizontally */

  /* The frosted glass effect */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); /* For Safari compatibility */
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 2;
}

.spinner {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  transform: translate(calc(50% - 60px), calc(50% - 60px));
  border: 16px solid white;
  border-radius: 50%;
  border-top: 16px solid $primary-orange;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
